import React, { Component, createRef } from "react";
import { Alert, Button, Modal, Tooltip, Typography, Row, Col, Form, Select, Input, message } from "antd";
import List from "../grid.component";
import { getWalletDetails, getLpWallets, walletFreeze, walletUnFreeze } from "./api";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import Loader from "../loader.component";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';
import { validateContentRule } from "../../utils/custom.validator";
import Info from "../info/Info";
import Moment from "react-moment";
import config from "../../config";

const { Paragraph } = Typography;

class WalletsDetials extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isModal: false,
      isLoader: false,
      isError: null,
      getWallets: [],
      lpWallets: [],
      searchObj: { lpName: "Main" },
      isFreeze: false,
      isUnFreeze: false,
      selection: [],
      selectedObj: {},
      freezeDetails: {},
      unFreezeDetails: {},
      isFreezeLoading: false,
      isUnFreezeLoading: false,
      isFreezeError: null,
      isUnFreezeError: null,
      isStatus: false,
      btnFreezeLoading: false,
      btnUnFreezeLoading: false,
      customerwalletsModal: false


    };
    this.formref = createRef();

    this.gridRef = createRef();
    this.customerWalletgridRef = createRef();
  }

  componentDidMount() {
   // this.getLpWalletDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchObj !== this.state.searchObj) {
      this.gridRef.current?.refreshGrid();
    }
  }

  getLpWalletDetails = async () => {
    const response = await getLpWallets();
    if (response.ok) {
      this.setState({ lpWallets: response.data, isError: null });
    } else {
      this.setState({ lpWallets: [], isError: apiCalls.isErrorDispaly(response) });
    }
  };

  handleLpChange = (data) => {
    this.setState({ searchObj: { lpName: data } });
  };

  freeze = async () => {
    if (this.state.selection?.length <= 0) {
      this.setState({
        ...this.state,
        isError: "Please select the one record",
      });
    }
    else {
      this.setState({ isFreeze: true, isFreezeError: null });
    }

  };

  handleFreezeCancel = () => {
    this.setState({ isFreeze: false, selectedObj: {}, selection: [] });
    this.formref.current?.resetFields();

  }
  unFreeze = async () => {
    if (this.state.selection?.length <= 0) {
      this.setState({
        ...this.state,
        isError: "Please select the one record",
      });
    } else {
      this.setState({ isUnFreeze: true, isUnFreezeError: null });
    }
  };

  handleUnFreezeCancel = () => {
    this.setState({ isUnFreeze: false, selectedObj: {}, selection: [] })
    this.formref.current?.resetFields();

  }

  getWalletView = async (props) => {
    this.setState({ isModal: true, isLoader: true, isError: null });
    try {
      const res = await getWalletDetails(props?.dataItem?.id);
      if (res.ok) {
        this.setState({ getWallets: res.data, isLoader: false });
      } else {
        this.setState({ isError: apiCalls.isErrorDispaly(res), isLoader: false });
      }
    } catch (error) {
      this.setState({ isError: error, isLoader: false });
    }
  };

  ModalClose = () => {
    this.setState({ isModal: false });
  };

  handleInputChange = (prop, e) => {
    const rowChecked = prop.dataItem;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowChecked.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 1);
    } else {
      selection.push(rowChecked.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowChecked,
      selection, error: null,
      warningMsg: null,
      errorMessage: null, isError: null
    });
  };

  handleFreezeOk = async (values) => {
    this.setState({ ...this.state, btnFreezeLoading: true, isFreezeError: null })
    let obj = Object.assign({});
    obj.walletId = this.state.selectedObj.id;
    obj.amount = values.amount
    obj.createdBy = this.props?.userConfig?.userName
    let response = await walletFreeze(obj)
    if (response.ok) {
      this.setState({ ...this.state, btnFreezeLoading: false, isFreezeError: null, isFreeze: false, isError: null, selectedObj: {}, selection: [] })
      this.gridRef.current?.refreshGrid();
      message.success({
        content: "Amount freezed successfully",
        className: "custom-msg",
        duration: 3
      });
      this.formref.current?.resetFields();

    }
    else {
      this.setState({ ...this.state, btnFreezeLoading: false, isFreezeError: apiCalls.isErrorDispaly(response) })

    }
  }

  handleUnFreezeOk = async (values) => {
    this.setState({ ...this.state, btnUnFreezeLoading: true, isUnFreezeError: null, isError: null })
    let obj = Object.assign({});
    obj.walletId = this.state.selectedObj.id;
    obj.amount = values.amount
    obj.createdBy = this.props?.userConfig?.userName

    let response = await walletUnFreeze(obj)
    if (response.ok) {
      this.setState({ ...this.state, btnUnFreezeLoading: false, isUnFreezeError: null, isUnFreeze: false, selectedObj: {}, selection: [] })
      this.gridRef.current?.refreshGrid();
      message.success({
        content: "Amount unfreezed successfully",
        className: "custom-msg",
        duration: 3
      });
      this.formref.current?.resetFields();

    }
    else {
      this.setState({ ...this.state, btnUnFreezeLoading: false, isUnFreezeError: apiCalls.isErrorDispaly(response) })

    }
  }
  numberValidator = async function (rule, value, callback) {
    if (value === ".") {
      return Promise.reject(`Please enter valid content`);
    }
    return Promise.resolve();
  };

  gridWallets = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <label className="text-center custom-checkbox">
          <input
            id={props.dataItem.id}
            name="check"
            type="checkbox"
            checked={this.state.selection.indexOf(props.dataItem.id) > -1}
            onChange={(e) => this.handleInputChange(props, e)}
            className="grid_check_box"
          />
          <span></span>
        </label>
      )
    },
    {
      field: "",
      title: "",
      width: 220,
      customCell: (props) => (
        <td>
          <img
            src={props.dataItem.impagePath}
            alt="coins"
            width="36"
            height="36"
            style={{ marginRight: 16 }}
          />
          {props.dataItem.walletCode}
        </td>
      ),
    },
    { field: "walletName", title: "Wallet Name", filter: true, width: 240 },
    { field: "type", title: "Type", filter: true, width: 100 },
    {
      field: "walletaddress",
      title: "Wallet Address",
      filter: true,
      width: 480,
      customCell: (props) => (
        <td>
          <div className="d-flex">
            <div className="batch-excel">
              <span>
                {props?.dataItem?.walletaddress && <Paragraph>{props?.dataItem?.walletaddress}</Paragraph>}
              </span>
            </div>
            {props.dataItem?.type !== "Fiat" && (
              <Tooltip title="Wallet Details">
                <button
                  className="icon md info c-pointer ml-8 btn-transparent"
                  onClick={() => this.getWalletView(props)}
                ></button>
              </Tooltip>
            )}
          </div>
        </td>
      ),
    },
    {
      field: "availableAmount",
      title: "Available Amount",
      filter: true,
      width: 240,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "frozenAmount",
      title: "Frozen Amount",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric",
    },

    {
      field: "totalAmount",
      title: "Total Amount",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "coinValueinNativeCurrency",
      title: "Amount in USD",
      filter: true,
      width: 240,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "", title: "", width: 70,
      customCell: (props) => (
        <td>
          <div className="d-flex align-center">

            <Tooltip title="History Details">
              <span
                className="icon md info c-pointer ml-8"
                onClick={() => this.showDetails(props)}
              />
            </Tooltip>
          </div>
        </td>)

    },

  ];



  customerWalletColumns = [
    { field: "createdBy", title: "Name", filter: true, width: 180 },
    { field: "amount", title: "Amount", filter: true, width: 300 },
    { field: "currency", title: "Currency", filter: true, width: 120 },
    { field: "status", title: "Action", filter: true, width: 120 },

   
    {
      field: "createdDate",
      title: "Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      locked: false,
      width: 220,
    },
  ]


  showDetails = () => {
    if (this.state.selection?.length <= 0) {
      this.setState({
        ...this.state,
        isError: "Please select the one record",
      });
    } else {
      this.setState({ ...this.state, customerwalletsModal: true })
    }
  }
  hideCustomerWalletsModal = () => {
    this.setState({ ...this.state, customerwalletsModal: false, isError: null, selection: [], selectedObj: {} })
  }
  render() {
    const { Option } = Select;
    const {
      isModal,
      isLoader,
      isError,
      getWallets,
      lpWallets,
      searchObj, innerGridUrl
    } = this.state;
    return (
      <>
        {isError && (
          <div style={{ width: "100%" }}>
            <Alert
              className="w-100 mb-16 align-center mt-0"
              type="warning"
              description={isError}
              showIcon
            />
          </div>
        )}
        <Row gutter={24}>
          {/* <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="lp"
              label="LP"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true,
                },
              ]}
            >
              <Select
                defaultValue={"Main"}
                className="cust-input"
                onChange={this.handleLpChange}
                placeholder="Select LP"
              >
                {lpWallets.map((item) => (
                  <Option key={item.id} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>
        <div className="text-right mb-16">
          <Tooltip title="Freeze">
            <span className="icon c-pointer md freeze mr-12" onClick={this.freeze} />
          </Tooltip>
          <Tooltip title="Unfreeze">
            <span className="icon c-pointer md unfreeze mr-12" onClick={this.unFreeze} />
          </Tooltip>
        </div>
        
        <List
          url={`${process.env.REACT_APP_GRID_API}Customer/Wallets/${window.location.pathname.split("/")[3]}`}
          ref={this.gridRef}
          ignoreSaved={true}
          //additionalParams={searchObj}
          columns={this.gridWallets}
          className="customerwallets-grid"
        />
        {this.state?.isStatus &&
          <Info Id={window.location.pathname.split("/")[3]}

            screenName={"customer wallets"}
          />
        }
        <Modal
          title={<p>Wallet Details</p>}
          visible={isModal}
          className="crypto-list"
          destroyOnClose
          width={600}
          closeIcon={
            <Tooltip title="Close">
              <button
                className="icon md x c-pointer btn-transparent"
                onClick={this.ModalClose}
              ></button>
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.ModalClose}
            >
              Close
            </Button>
          }
        >
          {isError && (
            <Alert
              className="w-100 mb-16 align-center mt-0"
              type="warning"
              description={isError}
              showIcon
            />
          )}
          {isLoader ? (
            <Loader />
          ) : (
            <table
              className="commision-table table-border edit-commition-table table-responsive"
              border="2"
            >
              <thead>
                <tr>
                  <th>Network</th>
                  <th>Wallet Address</th>
                </tr>
              </thead>
              <tbody>
                {getWallets.map((item) => (
                  <tr key={item?.id}>
                    <td>{item.network}</td>
                    <td>
                      <Paragraph className="mb-0" copyable>
                        {item.walletAddress}
                      </Paragraph>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal>

        <Modal
          title="Freezing Balance"
          visible={this.state.isFreeze}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleFreezeCancel} />
            </Tooltip>
          }
          footer={null}
        >

          <div>
            {this.state.isFreezeLoading && <Loader />}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleFreezeOk}
            >
              {this.state.isFreezeError !== undefined && this.state.isFreezeError !== null && this.state.isFreezeError !== "" && (
                <div style={{ width: '100%' }}>
                  <Alert className="w-100 mb-16 align-center mt-0" type="warning" showIcon description={this.state.isFreezeError} />
                </div>

              )}
              <div className="client-names">

                <p className="fw-400">Currency : <b>{this.state?.selectedObj?.walletCode}</b></p>
                <p className="fw-400">Available Amount :
                  <b> <NumberFormat value={this.state?.selectedObj?.availableAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} /></b>
                </p>
              </div>
              <Row gutter={24} className="mb-24 pb-24 border-bottom">

                <Col xs={24} sm={24} md={12} className="px-0">
                  <Form.Item
                    name="amount"
                    label="Amount to Freeze"
                    className="input-label fw-400 freeze-input"
                    rules={[
                      { required: true, message: "Is required" }
                      , {
                        validator: this.numberValidator
                      }
                    ]}
                  >
                    <div className="custom-input">

                      <NumberFormat
                        decimalSeparator="."
                        thousandSeparator={true}
                        customInput={Input}
                        className="cust-input"
                        placeholder="Enter Amount"
                        maxLength={19}
                        decimalScale={this.state?.selectedObj?.type == "Fiat" ? 2 : 8}
                        allowNegative={false}

                      />
                    </div>
                  </Form.Item>
                </Col>

              </Row>
              <Form.Item className="mb-0">

                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleFreezeCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"


                    loading={this.state.btnFreezeLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Unfreezing Balance"
          visible={this.state.isUnFreeze}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleUnFreezeCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.isUnFreezeLoading && <Loader />}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.handleUnFreezeOk}
            >
              {this.state?.isUnFreezeError !== undefined && this.state?.isUnFreezeError !== null && this.state?.isUnFreezeError !== "" && (
                <div style={{ width: '100%' }}>
                  <Alert className="w-100 mb-16 align-center mt-0" type="warning" showIcon description={this.state.isUnFreezeError} />
                </div>
              )}
              <div className="client-names">
                <p className="fw-400">Currency : <b>{this.state?.selectedObj?.walletCode}</b></p>
                <p className="fw-400">Frozen Amount :
                  <b> <NumberFormat value={this.state?.selectedObj?.frozenAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} /></b>
                </p>
              </div>
              <Row gutter={24} className="mb-24 pb-24 border-bottom">

                <Col xs={24} sm={24} md={12} className="px-0">
                  <Form.Item
                    name="amount"
                    label="Amount to Unfreeze"
                    className="input-label fw-400 px-2 freeze-input"
                    rules={[
                      { required: true, message: "Is required" }
                      , {
                        validator: this.numberValidator
                      }
                    ]}
                  >
                    <div className="custom-input">
                      <NumberFormat
                        decimalSeparator="."
                        thousandSeparator={true}
                        customInput={Input}
                        className="cust-input"
                        placeholder="Enter Amount"
                        maxLength={19}
                        decimalScale={this.state?.selectedObj?.type == "Fiat" ? 2 : 8}
                        allowNegative={false}

                      />
                    </div>
                  </Form.Item>
                </Col>

              </Row>
              <Form.Item className="mb-0">

                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleUnFreezeCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"


                    loading={this.state.btnUnFreezeLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="History Details"
          visible={this.state?.customerwalletsModal}
          width={1000}
          className="crypto-list footer-remove"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCustomerWalletsModal} />
            </Tooltip>
          }
          footer={
            <Button
              type="primary"
              className="primary-btn cancel-btn"
              onClick={this.hideCustomerWalletsModal}
            >
              Close
            </Button>
          }
        >
          <List
            url={process.env.REACT_APP_GRID_API + `Customer/Wallets/History/${this.state?.selectedObj?.id}`}
            ref={this.customerWalletgridRef}
            ignoreSaved={true}
            columns={this.customerWalletColumns}
            className="custom-status-grid"
            pSize={"All"}
            sortable={false}

            />
        </Modal>
      </>
    );
  }
}

const connectStateToProps = ({ customerStore, userConfig, permissions }) => ({
  permissions,
  customerStore,
  userConfig: userConfig.userProfileInfo,
  trackAuditLogData: userConfig.trackAuditLogData,
});

WalletsDetials.propTypes = {
  dataItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    impagePath: PropTypes.string,
    walletCode: PropTypes.string,
    walletaddress: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default connect(connectStateToProps)(WalletsDetials);
