import { Modal, Tooltip, Form, Select, Input, Button, Alert, message, Col, Row, DatePicker } from "antd";
import React, { Component } from "react";
import { getStates, ApproveorCancel, getLpOptions, getSubAccountLu, getBankPartnersLu } from "./api";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import Loader from "../loader.component";
import NumberFormat from "react-number-format";
import moment from "moment";
import config from "../../config";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { DYNAMIC_STATECHANGE_FIELDS } from './constants'
import { DYNAMIC_TEXT_FIELDS } from "../../utils/utilsconstants";

const { TextArea } = Input
class DepositStateChange extends Component {
    stateChangeForm;
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            statesLst: [],
            saveLoad: false,
            errorMessage: null,
            currentState: this.props?.selectedCustomer?.customerState,
            obj: {
                id: "",
                screenName: "Deposit",
                state: "",
                statusRemarks: "",
                rejectReason: "",
                bankTxnId: "",
                providerTxnId: "",
                transactionId: "",
                transactionrefId: "",
                isCheckBox: false,
                pcomission: null,
                pCommisionValue: null,
                sweepSourceCommisionValue: null,
                sweepDestinationCommissionValue: null,
                currency: "",
            },
            stateLoading: true,
            stateChange: {},
            btnDisabled: false,
            lpDetails: [],
            bankPartnerDetails: [],
            selectedLp: null,
            subAccountObj: {},
            isSubAccount: false,
            subAccountDetails: [],
            selectedSubAccount: {},
            selectedBankPartnerId: null
        }
        this.formref = React.createRef();

    }


    componentDidUpdate(prevProps) {
        if (this.props.visible && !prevProps.visible) {
            this.statusChange();
            this.getBankPartnerDetails()
            this.getSubAccounts()
        }
    }


    statusChange = async () => {
        this.setState({ ...this.state, stateLoading: true })
        const selectedBankParterId = this.state.bankPartnerDetails.find(item => item.id === this.props.selectedObj?.bankPartnerId)
        let tabName = this.props.selectedTab === 1 ? ((this.props.selectedObj?.isDigitaltransfer !== null) && "Recevie DigitalTransfer") || "Recevie Fiat" : "Recevie Crypto"
        let response = await getStates(tabName, this.props?.selectedObj?.status, this?.props?.selectedObj?.previousState)
        if (response.ok) {
            if(this.props?.selectedObj?.subAccounts !== null){
                this.setState({...this.state,isSubAccount:true})
              }
              else{
                this.setState({...this.state,isSubAccount:false})
              }
            setTimeout(() => {
                this.setState({ ...this.state, stateLoading: false })
            }, 3000);
            
            this.setState({
                ...this.state, statesLst: response.data, loading: false, appwerningMsg: null, btnDisabled: true,
                modal: true,
                warningMsg: null,
                error: null,
                stateChange: {
                    status: this.props.selectedObj.status,
                    type: this.props.selectedObj.type,
                    statusRemarks: this.props.selectedObj.statusRemarks,
                    isCheckBox: this.props.selectedObj.isCheckBox,
                    transactionrefId: this.props.selectedObj.transactionrefId,
                    pcomission: this.props.selectedObj.pcomission,
                    pCommisionValue: this.props.selectedObj.pcomissionvalue,
                    sweepDestinationCommissionValue: this.props.selectedObj.sweepDestinationcomissionValue,
                    rejectReason: this.props.selectedObj.rejectReason,
                    bankTxnId: this.props.selectedObj.bankTxnId,
                    providerTxnId: this.props.selectedObj.providerTxnId,
                    sweepSourceCommisionValue: this.props.selectedObj.sweepSourceComissionValue,
                    currency: this.props.selectedObj.currency,
                    previousState: this.props.selectedObj.previousState,
                    lp: this.props.selectedObj.lp,
                    subAccounts: this.props.selectedObj.subAccounts,
                    bankPartner: selectedBankParterId?.bankName || this.props.selectedObj?.bankPartnerName,

                },

            })
            this.formref?.current.setFieldsValue({
                ...this.state,
                status: this.props.selectedObj.status,
                statusRemarks: this.props.selectedObj.statusRemarks,
                isCheckBox: this.props.selectedObj.isCheckBox,
                transactionId: this.props.selectedObj.hash,
                pcomission: this.props.selectedObj.pcomission,
                pCommisionValue: this.props.selectedObj.pcomissionvalue,
                sweepDestinationCommissionValue: this.props.selectedObj.sweepDestinationcomissionValue,
                rejectReason: this.props.selectedObj.rejectReason,
                bankTxnId: this.props.selectedObj.bankTxnId,
                providerTxnId: this.props.selectedObj.providerTxnId,
                sweepSourceCommisionValue: this.props.selectedObj.sweepSourceComissionValue,
                currency: this.props.selectedObj.currency,
                previousState: this.props.selectedObj.previousState,
                BankValueDate: this.props.selectedObj.bankValueDate && moment(new Date(this.props.selectedObj.bankValueDate), "DD/MM/YYYY"),
                transactionrefId: this.props.selectedObj.transactionrefId,
                lp: this.props.selectedObj.lp || "Crypto.com",
                subAccounts: this.props.selectedObj.subAccounts,
                bankPartner: selectedBankParterId?.bankName ||this.props.selectedObj?.bankPartner|| this.props.selectedObj?.bankPartnerName,
            })
            this.getIpDetails()
            this.getBankPartnerDetails()
        }
    };

    handleOk = async (values) => {
        this.setState({ ...this.state, btnDisabled: true, saveLoad: true, warningMsg: null, })

        let statusObj = this.state.obj;
        statusObj.id = this.props.selectedObj.id;
        statusObj.AdminId = this.props.userConfig.id;
        statusObj.type = this.props.selectedTab === 1 ? "Fiat" : "Crypto";
        statusObj.status = values.status || this.props.selectedObj.status;
        statusObj.statusRemarks = values.statusRemarks;
        statusObj.isCheckBox = this.props.selectedObj.isCheckBox;
        statusObj.transactionrefId = values.transactionrefId;
        statusObj.pcomission = values.pcomission || this.props.selectedObj.pcomissionPercentage;
        statusObj.pCommisionValue = values.pCommisionValue;
        statusObj.BankValueDate = values.BankValueDate;
        statusObj.sweepDestinationCommissionValue = values.sweepDestinationCommissionValue;
        statusObj.sweepSourceCommisionValue = values.sweepSourceCommisionValue;
        statusObj.rejectReason = values.rejectReason
        statusObj.currency = this.props.selectedObj.currency || this.props.selectedObj.walletCode;
        statusObj.AprrovedBy = this.props.userConfig.userName;
        statusObj.ModifiedBy = this.props.userConfig.userName;
        statusObj.providerTxnId = values.providerTxnId || null;
        statusObj.bankTxnId = values.bankTxnId || null;
        statusObj.lp = values?.lp;
        statusObj.subAccounts = values?.subAccounts
        statusObj.bankPartnerId = this.state?.selectedBankPartnerId || this.props?.selectedObj?.bankPartnerId 

        statusObj.info = JSON.stringify(this.props.trackAuditLogData);
        let response = await ApproveorCancel(statusObj);
        if (this.state.deposit) {
            if (this.props.selectedObj.isCheckBox) {
                this.renderSucessCommonMsg(response, values);
            }
        } else {
            this.renderSucessCommonMsg(response, values);
        }

    };

    handleRemarks = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            isRemarks: value,
            btnDisabled: false
        });
    };

    handlePCommission=(e)=>{
        this.setState({
          ...this.state,
          btnDisabled: false
        });
      }
    
      handleSweepAtSource=(e)=>{
        this.setState({
          ...this.state,
          btnDisabled: false
        });
      }
    
      handleSweepAtDestination=(e)=>{
        this.setState({
          ...this.state,
          btnDisabled: false
        });
      }
    

    renderSucessCommonMsg = (response, values) => {
        if (response.ok) {
            this.props.dispatch(setCurrentAction(null));
            message.success({
                content: "Record " + (values.status || this.state.statusObj.status) + " successfully",
                className: "custom-msg",
                duration: 1
            });
            this.setState({
                ...this.state,

                error: null,
                stateLoading: true,
                btnDisabled: false,
                appwerningMsg: null,
                saveLoad: false
            }, () => {

                this.props.onSuccess()

            });

        } else {
            this.setState({
                ...this.state,
                btnDisabled: false,
                saveLoad: false,
                appwerningMsg: apiCalls.isErrorDispaly(response),
                error: response.status === 401 ? response.data.message : response.data,
            });
        }
    }

    renderRemarks() {
        if (![`${DYNAMIC_STATECHANGE_FIELDS.APPROVAL_IN_PROGRESS}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, `${DYNAMIC_STATECHANGE_FIELDS.REGISTERED}`, `${DYNAMIC_STATECHANGE_FIELDS.UNDER_REVIEW}`,].includes(this.state?.currentState?.toLowerCase())) {
            return <Form.Item className="input-label" name={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`} label={this.state?.currentState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` ? `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}` : `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_CLOSE}`} rules={[{ required: true, message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`, whitespace: true }, {
                validator: validateContentRule,
            },]}>
                <TextArea rows={3} className="" placeholder={this.state?.currentState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` ? `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}` : `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_CLOSE}`} maxLength={200} showCount disabled={this.props?.selectedCustomer?.customerState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` || this.props?.selectedCustomer?.customerState === "Closed"} />
            </Form.Item>
        }
    }
    getIpDetails = async () => {
        let response = await getLpOptions()
        if (response.ok) {
            this.setState({ ...this.state, lpDetails: response.data,appwerningMsg: null })
        } else {
            this.setState({ ...this.state, appwerningMsg: apiCalls.isErrorDispaly(response), lpDetails: [] })
        }
    }
    getBankPartnerDetails = async () => {
        let response = await getBankPartnersLu(this.props?.selectedObj?.currency)
        if (response.ok) {
            this.setState({ ...this.state, bankPartnerDetails: response.data, appwerningMsg: null })
        } else {
            this.setState({ ...this.state, appwerningMsg: apiCalls.isErrorDispaly(response), bankPartnerDetails: [] })
        }
    }
    bankPartnerChange = (data) => {
        this.setState({ ...this.state, selectedBankPartnerId: data })
    }
    lpChange = async (data) => {
        
        this.setState({ ...this.state, selectedLp: data, isSubAccount: false, subAccountObj: {} });
        this.formref.current.setFieldsValue({subAccounts :null})

        if (data === "Crypto.com") {
            const filteredObj = this.state?.lpDetails.find(
                (item) => item.name === "Crypto.com" && item.text === "SubAccount"
            );
            this.setState({ ...this.state, subAccountObj: filteredObj, isSubAccount: true })
            this.getSubAccounts()
        }
        else{
            this.setState({ ...this.state, isSubAccount: false, subAccountObj: {} });

        }
    };

    getSubAccounts = async()=>{
        let response = await getSubAccountLu()
        if (response.ok) {
            this.setState({ ...this.state, subAccountDetails: response.data })
        }
        else {
            this.setState({ ...this.state, appwerningMsg: apiCalls.isErrorDispaly(response) })
        }}
    lpSubAccountChange = (data) => {
        this.setState({ ...this.state, selectedSubAccount: data })

    }
    getAppWarningMsg = () => (
        this.state.appwerningMsg !== undefined && this.state.appwerningMsg !== null && (
            <Alert
                className="mb-12"
                type="error"
                description={this.state.appwerningMsg}
                showIcon
            />
        )
    )
    isField1Disabled(disabledStatuses) {
        const { selectedObj } = this.props;
        return disabledStatuses.includes(selectedObj.status);
    }
    validateNumber = (_, validNumberValue) => {
        if (validNumberValue === ".") {
            return Promise.reject(`${DYNAMIC_STATECHANGE_FIELDS.PLEASE_ENTER_VALID_CONTENT}`);
        }
        return Promise.resolve();
    }

    stateChange = (val) => {
        this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "", })
        let { stateChange } = this.state;
        stateChange.status = val;
        this.setState({ ...this.state, stateChange, btnDisabled: false });
        if(val === "Swept"){
            this.setState({ ...this.state,isSubAccount : true,btnDisabled:false });

        }
        if(val === "Compliance Check" && this?.props?.selectedObj?.subAccounts == null){
            this.setState({ ...this.state,isSubAccount : false,btnDisabled:false });
        }
        if(val === "Rejected" && this?.props?.selectedObj?.subAccounts == null){
            this.setState({ ...this.state,isSubAccount : false,btnDisabled:false });
        }
        if (val === `${DYNAMIC_STATECHANGE_FIELDS.REJECT}` || val === `${DYNAMIC_STATECHANGE_FIELDS.APPROVE}`) {
            this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "", })
        }
        if (val === `${DYNAMIC_STATECHANGE_FIELDS.PENDING} ` || val === `${DYNAMIC_STATECHANGE_FIELDS.APPROVE}`) {
            this.formref.current.setFieldsValue({
                pcomission: this.state.selectedObj?.pcomission
                    ? this.state.selectedObj?.pcomission
                    : null,
            });
        }
      
    };
    onhandleClose = () => {
        this.formref.current?.resetFields();
        this.setState({ ...this.state, isSubAccount: false })
        this.props?.onClose()
    }
    render() {
        const { deposit, visible } = this.props;
        return <Modal
            title={`${DYNAMIC_STATECHANGE_FIELDS.CONFIRM_APPROVE_REJECT}`}
            className="custom-width text-break deposit-pop"
            visible={visible}
            closeIcon={
                <Tooltip title={`${DYNAMIC_STATECHANGE_FIELDS.CLOSE}`}>
                    <span className="icon md x c-pointer"
                        onClick={() => this.onhandleClose()}
                    />
                </Tooltip>
            }
            footer={null}>
            <div>
                {this.state.stateLoading && <Loader />}
                <Form
                    ref={this.formref}
                    className="ant-advanced-search-form"
                    autoComplete="off"
                    onFinish={this.handleOk}>
                    {this.getAppWarningMsg()}
                    {deposit && (
                        <div className="mb-8">
                            <Alert
                                message={
                                    <Form.Item
                                        name={`${DYNAMIC_STATECHANGE_FIELDS.IS_CHECKBOX}`}
                                        valuePropName={`${DYNAMIC_STATECHANGE_FIELDS.CHECKED}`}
                                        className="mb-0"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                            new Error(`${DYNAMIC_STATECHANGE_FIELDS.PLEASE_SELECT_CHECKBOX}`)
                                                        ),
                                            },
                                        ]}>
                                        <div className="d-flex">
                                            <label className="text-center custom-checkbox mr-8">
                                                <input
                                                    name={`${DYNAMIC_STATECHANGE_FIELDS.CHECK}`}
                                                    type={`${DYNAMIC_STATECHANGE_FIELDS.CHECKBOX}`}
                                                    checked={this.props.selectedObj?.isCheckBox !== null ? this.props.selectedObj?.isCheckBox : false }
                                                    onChange={(e) => this.props.onNote(e)}
                                                    disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`])}
                                                />
                                                <span></span>{" "}
                                            </label>
                                            Important Note:
                                        </div>
                                    </Form.Item>
                                }
                                description={<p className="fw-400 fs-14 mb-5 l-height-normal">Please ensure that you have transferred funds from Signet to Kraken prior to approval. Please click the checkbox above to confirm.</p>} type="info"
                                className="imp-alert"
                            />
                        </div>
                    )}

                    <Row gutter={24} className="mb-24 pb-24 border-bottom">
                        {deposit && (
                            <Col xs={24}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.TRANSACTION_REFID}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.REFERENCE_ID}`}
                                    className="input-label"
                                    rules={[
                                        {
                                            required: this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`,
                                            message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateContentRule,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REFERENCE_ID}`}
                                        maxLength={20}
                                        className="cust-input"
                                        disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`])}

                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={`${DYNAMIC_STATECHANGE_FIELDS.STATUS}`}
                                label={`${DYNAMIC_STATECHANGE_FIELDS.STATE}`}
                                className="input-label"
                                rules={[
                                    {
                                        required: true,
                                        message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}>
                                <Select
                                    className="cust-input mb-0"
                                    onChange={(e) => this.stateChange(e)}
                                    disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`])}
                                    placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_STATE}`}>
                                    {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                       
                        {this.props.selectedTab == 2 && <>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.PROVIDER_TXNID}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.KRAKEN_BINANCE}`}
                                    className="input-label"
                                    rules={[

                                        {
                                            validator: validateContentRule
                                        }
                                    ]}

                                >
                                    <Input
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.KRAKEN_BINANCE}`}
                                        className="cust-input"
                                        maxLength={50}
                                        disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}

                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXNID}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXN_ID}`}
                                    className="input-label"
                                    rules={[

                                        {
                                            validator: validateContentRule
                                        }
                                    ]}

                                >
                                    <Input
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXN_ID}`}
                                        className="cust-input"
                                        maxLength={50}
                                        disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}


                                    />
                                </Form.Item>
                            </Col></>}
                        {this.state.stateChange?.previousState && deposit && <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUSSTATE}`}
                                label={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUS_STATE}`}
                                className="input-label"
                            >
                                <Input placeholder={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUS_STATE}`}
                                    disabled
                                    maxLength={150}
                                    className='cust-input' />
                            </Form.Item>
                        </Col>}

                        {(deposit && (this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`) &&
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.P_COMMISSION}`}
                                    label={<div>Provider fees

                                    </div>}
                                    rules={[
                                        {
                                            validator: this.validateNumber
                                        }
                                    ]}
                                    className="input-label"
                                >
                                    <NumberFormat
                                        decimalScale={2}
                                        className="cust-input"
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.PROVIDER_FEES}`}
                                        allowNegative={false}
                                        maxlength={13}
                                        onChange={(e)=>this.handlePCommission(e)}

                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {((this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`) &&
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_SOURCE_COMMUNICATION}`}
                                    label={<div>Sweep at source	</div>}
                                    rules={[
                                        {
                                            validator: this.validateNumber
                                        }
                                    ]}
                                    className="input-label"
                                >
                                    <NumberFormat
                                        decimalScale={deposit ? 2 : 8}
                                        className="cust-input"
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_AT_SOURCE}`}
                                        allowNegative={false}
                                        maxlength={13}
                                        onChange={(e)=>this.handleSweepAtSource(e)}

                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {((this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`) &&
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_DESTINATION_COMMUNICATION}`}
                                    label={<div>Sweep at destination

                                    </div>}
                                    rules={[
                                        {
                                            validator: this.validateNumber
                                        }
                                    ]}
                                    className="input-label"
                                >
                                    <NumberFormat
                                        decimalScale={deposit ? 2 : 8}
                                        className="cust-input"
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_DESTINATION}`}
                                        allowNegative={false}
                                        maxlength={13}
                                        onChange={(e)=>this.handleSweepAtDestination(e)}

                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {((this.props.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`) && this.props.selectedTab === 1 && <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE_DATE}`}
                                className="input-label cust-datepicker-cursor"
                                label={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE}`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`
                                    }
                                ]}
                            >
                                <DatePicker
                                    format={config?.dateFormates?.dateFormate}
                                    placeholder={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE}`}
                                    className="cust-input"
                                    disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`])}
                                    disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                            </Form.Item>
                        </Col>)}

                        {(this.props.selectedTab == 1 && (this.state?.stateChange?.status == `${DYNAMIC_STATECHANGE_FIELDS.SWEPT}` || this.props?.selectedObj?.lp !== null)) &&
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.NAME_LP}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.LP}`}
                                    className="input-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                                            whitespace: true

                                        }
                                    ]}
                                >
                                    <Select
                                        className="cust-input mb-0"
                                        onChange={(e) => this.lpChange(e)}
                                        disabled={this.props?.selectedObj?.lp !== null}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_LP}`}
                                        defaultValue={"Crypto.com"}
                                    >
                                        {this.state.lpDetails?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                        }
                        {(this.props.selectedTab == 1 && this.state?.isSubAccount) &&
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.NAME_SUBACCOUNT}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.LPSUBACCOUNT}`}
                                    className="input-label"
                                  
                                >
                                    <Select
                                        className="cust-input mb-0"
                                        onChange={(e) => this.lpSubAccountChange(e)}
                                        disabled={this.props?.selectedObj?.subAccounts !== null}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_LP_SUBACCOUNT}`}
                                    >
                                        {this.state.subAccountDetails?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        {(this.props.selectedTab == 1 && (this.state?.stateChange?.status == `${DYNAMIC_STATECHANGE_FIELDS.SWEPT}` || this.props?.selectedObj?.lp !== null)) &&

                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.NAME_BANKDETAILS}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.BANK_DETAILS}`}
                                    className="input-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`
                                        }
                                    ]}
                                >
                                    <Select
                                        className="cust-input mb-0"
                                        onChange={(e) => this.bankPartnerChange(e)}
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_BANKDETAILS}`}
                                        disabled={this.props?.selectedObj?.bankPartner !== null}
                                    >
                                        {this.state.bankPartnerDetails?.map(item => <Select.Option value={item?.id} key={item?.id}>{item?.bankName}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        <Col xs={24}>
                            {this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` && (
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.REJECT_REASEON}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}`}
                                    className="input-label"
                                    rules={[
                                        {

                                            required: true,
                                            message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateContentRule,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}`}
                                        maxLength={100}
                                        rows={4}
                                        disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`,])}
                                        showCount
                                    />
                                </Form.Item>
                            )}
                        </Col>


                        <Col xs={24}>
                            {(this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`) && (
                                <Form.Item
                                    name={`${DYNAMIC_STATECHANGE_FIELDS.STATUS_REMARKS}`}
                                    label={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`}
                                    className="input-label"
                                    rules={[
                                        {

                                            message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateContentRule,
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`}
                                        maxLength={100}
                                        rows={4}
                                        showCount
                                        onChange={(e) => this.handleRemarks(e)}

                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Form.Item className="mb-0">
                        <div className="text-right">
                            <Button
                                type="primary"
                                className="primary-btn cancel-btn mr-8"
                                onClick={() => this.onhandleClose()}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                key="submit"
                                className="primary-btn"
                                htmlType="submit"
                                loading={this.state.saveLoad}
                                disabled={this.state?.btnDisabled}
                            >

                                Save
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    }
}

const connectStateToProps = ({ userConfig }) => {
    return {
        userConfig: userConfig.userProfileInfo,
        trackAuditLogData: userConfig.trackAuditLogData,
    };
};
export default connect(
    connectStateToProps,
)(DepositStateChange);